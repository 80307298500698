import { useState, useEffect } from "react";
import {
  FormContainer,
  Page,
  Logo,
  ImageContainer,
  FormGroup,
  Label,
  ContainerModal,
  ContentBox,
} from "./styles";
import logo from "../../assets/logo.png";
import api from "../../service/api";
import { toast } from "react-toastify";
import Button from "../../components/button/button";
// import CurrencyInput from "react-currency-input-field";
import { NumericFormat } from "react-number-format";

export default function Home() {
  const [form, setForm] = useState({
    value: "",
    payment: "",
    network: "",
    valueType: "",
  });
  const [valueCotation, setValueCotation] = useState<string>("");
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalId, setShowModalId] = useState(false);
  const [showNetworkSelector, setShowNetworkSelector] = useState(false);
  const [responseId, setResponseId] = useState("");

  const [selectedCryptocurrency, setSelectedCryptocurrency] = useState("");

  const cryptocurrencyNetworks = {
    BTC: ["Litghtning", "BTC", "BNB"],
    ETH: ["Erc 20", "Bep 20", "Bep 2"],
    USDT: ["Bep 20", "Erc 20", "Matic"],
  };

  const handleCryptocurrencyChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = event.target;

    setSelectedCryptocurrency(value);

    setForm((prevState) => ({
      ...prevState,
      valueType: value,
    }));

    if (value) {
      setShowNetworkSelector(true);
    } else {
      setShowNetworkSelector(false);
    }
  };

  const handleNetworkChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setForm((prevState) => ({
      ...prevState,
      network: value,
    }));
  };

  const handlePaymentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setForm((prevState) => ({
      ...prevState,
      payment: value,
    }));

    if (value === "criptomoedas") {
      setShowModal(true);
    }
  };

  const handleClose = (open?: boolean) => {
    setShowModal(false);
    setShowModalId(false);

    if (open) {
      window.open("https://t.me/P2PIX_OFC", "_blank");
    }
  };

  const handleCopy = async (content: string) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(content);
        toast.success("ID copiado", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        // Fallback for older browsers
        const textArea = document.createElement("textarea");
        textArea.value = content;
        document.body.appendChild(textArea);
        textArea.select();
        try {
          const successful = document.execCommand("copy");
          if (successful) {
            toast.success("ID copiado", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            throw new Error("Fallback: Copying text command was unsuccessful");
          }
        } catch (err) {
          toast.error("Não foi possível copiar seu ID", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        document.body.removeChild(textArea);
      }
    } catch (error) {
      toast.error("Não foi possível copiar seu ID", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleButtonClick = () => {
    if (
      (form.payment !== "criptomoedas" && form.payment !== "") ||
      (form.valueType && form.network)
    ) {
      const syntheticEvent = {
        preventDefault: () => {},
        stopPropagation: () => {},
        currentTarget: {} as HTMLFormElement,
      } as React.FormEvent<HTMLFormElement>;

      handleSubmit(syntheticEvent);
    }

    if (valueCotation) {
      setShowPaymentOptions(true);
    }
  };

  const fetchCotations = async (currencyValue: string) => {
    const numericValue = parseFloat(currencyValue.replace(/[R$\.,]/g, ""));

    if (numericValue >= 500) {
      try {
        const response = await api.get("/sales", {
          params: {
            fromCurrency: "USDT",
            toCurrency: "BRL",
            value: numericValue,
          },
        });

        setValueCotation(response.data.USDTValue);

        setForm((prevForm) => ({
          ...prevForm,
          cotations: response.data.USDTValue,
        }));
      } catch (error: any) {
        toast.error(`${error.response.data.message}` || "Erro inesperado", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const numericValue = parseFloat(form.value.replace(/[R$\.,]/g, ""));
    if (numericValue < 500) {
      toast.error(
        "O valor de comprar ou de venda não pode ser menor que R$500",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      return;
    }

    try {
      const formData = {
        value: form.value,
        payment: form.payment,
        network: form.network,
        valueType: form.valueType,
      };

      const response = await api.post("/sales", formData);

      if (response.status === 200) {
        setResponseId(response.data[0].id);
        setShowModalId(true);
        toast.success(
          `Compra confirmada! 
           `,
          {
            position: toast.POSITION.TOP_RIGHT,

            // onClose: () => window.location.href = 'https://t.me/P2PIX_OFC'
          }
        );
      }
    } catch (error: any) {
      toast.error(`${error.response.data.message}` || "Erro inesperado", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handlerInputChange = (event: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });

    if (name === "value") {
      fetchCotations(value);
    }
  };

  return (
    <Page>
      <ImageContainer>
        <Logo src={logo} alt="Logo" />
      </ImageContainer>

      <FormContainer onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Valor em Reais(R$)</Label>

          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            allowNegative={false}
            required
            className="input-mask"
            name="value"
            value={form.value}
            onValueChange={(values) => {
              const { value } = values;
              handlerInputChange({ target: { name: "value", value } });
            }}
            placeholder="R$(Mínimo R$500)"
          />

        </FormGroup>

        <FormGroup>
          <Label>Valor estimado em USDT:</Label>
          <input
            required
            className="input-mask"
            name="valueCotation"
            value={valueCotation}
            type="text"
            onChange={handlerInputChange}
            placeholder="Aguardadndo cotação"
          />
        </FormGroup>

        {showPaymentOptions && (
          <FormGroup>
            <Label>Opções de pagamento:</Label>
            <select
              className="cripto-select"
              name="payment"
              onChange={handlePaymentChange}
            >
              <option value="">Selecione uma opção de pagamento</option>
              <option value="Pix">Pix</option>
              <option value="Boleto">Boleto</option>
              <option value="Transferência Bancária">
                Transferência Bancária
              </option>
              <option value="criptomoedas">Criptomoedas</option>
            </select>
          </FormGroup>
        )}

        <ContainerModal
          open={showModal}
          onClose={() => handleClose(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <ContentBox>
            <h2 id="simple-modal-title">Escolha uma criptomoeda</h2>
            <select
              className="cripto-select"
              name="cryptocurrency"
              onChange={handleCryptocurrencyChange}
            >
              <option value="">Selecione uma criptomoeda</option>
              <option value="BTC">BTC</option>
              <option value="ETH">ETH</option>
              <option value="USDT">USDT</option>
            </select>

            {showNetworkSelector && (
              <ContentBox>
                <h2 id="simple-modal-title">Escolha uma rede</h2>
                <select
                  className="cripto-select"
                  name="network"
                  onChange={handleNetworkChange}
                >
                  <option value="">Selecione uma rede</option>
                  {cryptocurrencyNetworks[
                    selectedCryptocurrency as keyof typeof cryptocurrencyNetworks
                  ].map((network: any) => (
                    <option key={network} value={network}>
                      {network}
                    </option>
                  ))}
                </select>
              </ContentBox>
            )}

            <button className="modal-button" onClick={() => handleClose(false)}>
              Fechar
            </button>
          </ContentBox>
        </ContainerModal>

        <Button
          type="button"
          text="Prosseguir"
          color="#2754ba"
          onClick={handleButtonClick}
        />
      </FormContainer>

      <ContainerModal
        open={showModalId}
        onClose={() => handleClose(true)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ContentBox>
          <h2 id="simple-modal-title">Compra confirmada!</h2>
          <p id="simple-modal-description">
            Copie seu ID e cole no chat do telegram ao cliclar em fechar ID:{" "}
            {responseId}
          </p>

          <Button
            type="button"
            text="Copiar ID"
            color="#2754ba"
            onClick={() => handleCopy(responseId)}
          />

          <Button
            type="button"
            text="Fechar"
            color="#2754ba"
            onClick={handleClose}
          />
        </ContentBox>
      </ContainerModal>
    </Page>
  );
}
